<script setup lang="ts">
definePageMeta({ isPublic: true, layout: 'intro' })

const { organizationsPath } = useAppConfig()
</script>

<template>
  <div class="text-center">
    <HeadingTitle>Welkom!</HeadingTitle>
    <HeadingText>Maak een keuze:</HeadingText>

    <div class="mt-4 flex flex-col justify-center gap-2 md:flex-row">
      
      <HomeWhoBotton
        icon="material-symbols:volunteer-activism-outline-rounded"
        to="/kies-taal"
        label="Nieuwkomer"
      />
      <HomeWhoBotton
        icon="ph:buildings"
        :to="organizationsPath"
        label="Algemeen"
      />
      
    </div>
  </div>
</template>
